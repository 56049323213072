import React from 'react';

const InternalServerError = () => {

    return (

        <h1>
            500 Internal Server Error
        </h1>

    );

}

export default InternalServerError;