import react from "react";

const Enums = () => {

    return (
         <>
         </>
    );

}

export default Enums;
