import React, { useState } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Metric, createMetric } from "../../api/detecting/APIDetectingMetrics";
import { useAuth } from "react-oidc-context";
import { API_RESPONSE } from "../../components/api/ApiCaller";

interface APIMetricResponse {
  status: number;
  data?: Metric;
}

interface APICreateMetricResponse {
  redirect?: string;
  response?: APIMetricResponse;
}

const CreateMetric = () => {
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        sqlQuery: ""
    });
    const navigate = useNavigate();
    const auth = useAuth();
    const [errorMsg, setErrorMsg] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const token = auth?.user?.access_token;
            if (!token) return;

            const metricData: Metric = {
                id: 0,
                name: formData.name,
                description: formData.description,
                sqlQuery: formData.sqlQuery
            };


            const apiResponse: API_RESPONSE = await createMetric(metricData, token);
            const response: APICreateMetricResponse = {
              redirect: apiResponse.redirect,
              response: {
                status: apiResponse.response.status,
                data: apiResponse.response.data as Metric | undefined
              }
            };

            if (response.response?.status === 400) {
                setErrorMsg("Metric has not been created. The name is not unique.");
            }
            else if (response.redirect) {
                navigate(response.redirect);
            } else {
                const responseData = response.response?.data;
                if (responseData) {
                    const createdMetricId = responseData.id;
                    navigate(`/metricDetail/${createdMetricId}`);
                } else {
                    console.error("Invalid response data:", response.response);
                }
            }
        } catch (error) {
            console.error("Error creating metric:", error);
        }
    };

    return (
        <Container>
          <p>/ <a href="/definition">Definition</a> / <a href="/metrics">Metrics</a> / <a href="/createmetric">Create New Metric</a></p>
          <h1>Create New Metric</h1>
          {errorMsg && <p className="text-danger">{errorMsg}</p>}
          <Form onSubmit={handleFormSubmit} style={{ padding: "20px" }}>
            <Form.Group controlId="metricName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group controlId="metricDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" name="description" value={formData.description} onChange={handleInputChange} required />
            </Form.Group>
            <Form.Group controlId="metricSqlQuery">
              <Form.Label>SQL Query</Form.Label>
              <Form.Control as="textarea" rows={3} name="sqlQuery" value={formData.sqlQuery} onChange={handleInputChange} required />
            </Form.Group>
            <div style={{ paddingTop: "20px" }}>
            <Button variant="success" type="submit" style={{ marginRight: "10px" }}>Create Metric</Button>
            <a href="/metrics"><Button variant="danger">Cancel</Button></a>
            </div>
          </Form>
        </Container>
      );
};

export default CreateMetric;
