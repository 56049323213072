import React from 'react';

const Forbidden = () => {

    return (

        <h1>
            403 Forbidden
        </h1>

    );

}

export default Forbidden;